import React from "react"; 
import Header from "../components/Header";
import Footer from "../components/Footer"; 
import Breadcrumb from "../components/Breadcrumb";
const Alumni = () => {

  return (
    <>
      <Header />
      <Breadcrumb mid="Alumni" breadcrumbName="Alumni" />

      <div className="innerSection">
        <div className="container">         
          <div className="row">
            <div className="col-md-12">
            <div className="title">
                <div>
                    <h1>Alumni</h1>
                </div>
            </div>
               
            </div>
            <div className="col-md-12">
            <div class="table-responsive v-center">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Alumni Name</th>
                                 <th>Photo</th>
                                <th>Educational Qualification</th>
                                <th>Present Designation</th>
                                <th>Achievement</th>  
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>Ms. Prachi Singh IPS</td>
                                <td><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/5.jpg" alt="St mary school mirzapur" /></td>
                                <td>BA LLB(Hons), LLM, NET, JRF,  IPS</td><td>Superintendent of Police Shravasti U.P</td>
                                <td>Excellent IPS  Officer</td></tr><tr><td>Dr. Vaibhav Mishra MBBS MD DM(Cardiology)</td>
                                <td><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/2.jpg" alt="St mary school mirzapur"  /></td><td>MBBS MD Doctorate of Medicine(DM) Cardiology</td>
                                <td>Cardiologist Safdarjung hospital New Delhi</td><td>Excellent Practitioner in Medical field</td></tr>
                                <tr><td>Dr. Robin Jain BDS</td>
                                <td><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/7.jpg" alt="St mary school mirzapur"  /></td>
                                <td>BDS, Member of Indian Dental Association</td><td>Dental Surgeon Baji Rao Katra, MZP</td>
                                <td>Excellent Practitioner in Dental Surgery</td></tr><tr><td>Dr. Shwetank Manjul MBBS MS</td>
                                <td><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/System.Web.UI.HtmlControls.HtmlInputHidden.jpg" alt="St mary school mirzapur" /></td><td>MBBS MS</td>
                                <td>Otorhinolaryngologist and Head and neck surgeon</td><td>Head and Neck Surgeon</td>
                                </tr>

                               

                        </tbody>
                    </table>
                </div>
            </div>
          </div>
        </div>
      </div>
      <Footer /> 
    </>
  )
}

export default Alumni