import React, {useState, useEffect}from 'react';
import {Link} from 'react-router-dom'

import {getPrincipalMessage} from '../Service/Api'
const PrincipalMessage = () => { 
    const [data, setData] = useState([]); 
  
    useEffect(() => {
      const fetchData = async () => {
        const messages = await getPrincipalMessage();
        const filterData = messages.filter((item) => item.category === "Principal")  
        setData(filterData); 
      };
      fetchData();
    }, []);

    return (
        <div>
        {data.length > 0 ? data.map((item, index) =>(

            <div className="item" key={index}>
                <div className="message-block">
                    <div>
                    <div class="message-title">
                    Principal Message
                    </div>
                    <div className='message-content animateMe' data-animation="fadeInLeft">
                        
                        <div className='content-clamp' dangerouslySetInnerHTML={{ __html: item.message}} />
                        <div className='button abt-button'>
                            <a href="/PrincipalMessage">
                            <img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Read-more.png" alt="St Mary school, Mirzapur" />
                            </a></div>
                    </div>
                    </div>
                    <div className='message-image animateMe' data-animation="fadeInRight">
                        <img src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="St Mary school, Mirzapur"></img>
                        <img src="https://webapi.entab.info/api/image/SMSMU/public/Images/principal-quo.png" className="quotes"  alt="St Mary school, Mirzapur"></img>
                        <div className='princi-Name'>
                            <h3>{item.name}</h3>
                            <p>{item.designation}</p>
                        </div>
                    </div>
                </div>
            </div>
              )):(
                <div className="item">
                <div className="message-block">
                    <div>
                    <div class="message-title">
                    Principal Message
                    </div>
                    <div className='message-content animateMe' data-animation="fadeInLeft">
                        
                        <p><b>My Dear Students, Parents, and well-wishers, </b><br></br>Greetings to all of you. I am happy to be here at St. Mary School as the Principal of this premier education institution in Mirzapur. I am sure with the cooperation of our parents, students, and all the stakeholders we can scale the new heights in molding the young minds who are given to our care. It gives me immense joy to pen down these few lines to you through the school, very specially, in thee days, the details of regular online activities. I wish that you browse through the pages of our websites, atleast once in a week to get the necessary information and updates.</p>
                        <div className='button abt-button'><Link to="/PrincipalMessage"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Read-more.png" alt="St Mary school, Mirzapur" /></Link></div>
                    </div>
                    </div>
                    <div className='message-image animateMe' data-animation="fadeInRight">
                        <img src="https://webapi.entab.info/api/image/SMSMU/public/Images/principal-message.jpg" alt="St Mary school, Mirzapur"></img>
                        <img src="https://webapi.entab.info/api/image/SMSMU/public/Images/principal-quo.png" className="quotes" alt="St Mary school, Mirzapur"></img>
                        <div className='princi-Name'>
                            <h3>Rev. Fr. Jacob Bona D' Souza</h3>
                            <p>Principal</p>
                        </div>
                    </div>
                </div>
            </div>
                 
            )}
            </div>
        
    );
};

export default PrincipalMessage;
