import React, { useEffect, useState } from "react";
import { getGallery } from "../Service/Api";
import LazyLoad from 'react-lazyload';

function Gallery() {
   const [data, setData] = useState([]);
   useEffect(() => {
     const fetchData = async () => {
       const galleryData = await getGallery();
       setData(galleryData);
     };
     fetchData();
   }, []);

   useEffect(() => {
     console.log("events_data", data[0]);
   }, [data]);


  return (
    <>
      <div className="">
      <div className="title center">
                    <div>
                        <p>School in the images</p>
                        <h3>Gallery section</h3>
                    </div> 
                </div>
        <div className="container gallery-main animateMe" data-animation="fadeInUpBig">
          <div className="row">
          {data?.length > 0 ? data?.filter((div) => div.category === "PhotoGallery").map((item, index) => (
            <div className="col-lg-4 col-sm-6" key={index}> 
              <div className="gallery-blk">
              <LazyLoad>  <img
                  src={`https://webapi.entab.info/api/image/${item.attachments[0]}`}
                  className="img-fluid lg-mid"
                  alt="St mary school mirzapur" 
                /></LazyLoad>
                <div className="overlay">
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>

            </div>
               )) : (
                <div className="col-lg-3 col-sm-6">
              <div className="gallery-blk">
                <img
                  src="https://webapi.entab.info/api/image/SMSMU/public/Images/dummy.jpg"
                  className="img-fluid lg-mid"
                  alt="St mary school mirzapur" 
                />
                <div className="overlay">
                  <h3>Gallery Title</h3>
                  <p>Gallery Description</p>
                </div>
              </div>

            </div>
                )}
         

          </div>
          
        </div>
        <div className='button abt-button view-gal'><a href="/Gallery"><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/View-Gallery.png" /></a></div>
      </div>
    </>
  );
}

export default Gallery;
