import React, { useState } from 'react';
import { postTransferCertificate } from '../Service/Api';
import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"

const TC = () => {
  const [admission, setAdmission] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const handleAdmissionNoChange = (event) => {
    setAdmission(event.target.value);
    setError(""); // Clear error on user input
  };

  const handleFullNameChange = (event) => {
    setName(event.target.value);
    setError(""); // Clear error on user input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !admission) {
      setError("Please fill in all fields");
      return;
    }

    try {
      const response = await postTransferCertificate(name, admission);
      if (response) {
        console.log(response.data);
        // Potentially navigate or show success message
        fetch(`https://webapi.entab.info/api/image?url=${response.data}`)
          .then(response => response.blob())  // Assuming the data is in a blob format; adjust if different
          .then(blob => {
            // Create a link element, use it to download the blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'tc.jpg';  // Set the file name and extension
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            // alert('Your download has started.');  // Optional: Notify the user
          })
          .catch(() => alert('Failed to download data.'));  // Error handling
      }
    } catch (error) {
      console.error("Failed to post data", error);
      setError("Failed to process your request. Please try again later.");
    }
  };

  return (
    <>
      <Header />
      <Breadcrumb mid="TC" breadcrumbName="Transfer Certificate" />
      <div className="innerSection">
        <div className="container">
          <div className="col-md-12">
            <div className="tcblock">
              <div className="tc">
                <div>
                  <div className="formfil">
                    <form onSubmit={handleSubmit} className="tc">
                      <div className="text_box_sec form-group">
                        <label htmlFor="admission"><p>Admission No.</p></label>
                        <input
                          type="text"
                          placeholder="Admission Number"
                          id="admission"
                          className='form-control'
                          value={admission}
                          onChange={handleAdmissionNoChange}
                        />
                      </div>
                      <div className="text_box_sec form-group">
                        <label htmlFor="name"><p>Student Full Name</p></label>
                        <input
                          type="text"
                          placeholder="Student Name"
                          id="name"
                          className='form-control'
                          value={name}
                          onChange={handleFullNameChange}
                        />
                      </div>
                      <div className="text_box_sec">
                        <input
                          type="submit"
                          name="btnshwtcdetails"
                          value="View Details"
                          id="btnshwtcdetails"
                          className="btn btn-danger viewdetailsbtn"
                        />
                      </div>
                      {error && <p className="error">{error}</p>}
                    </form>
                  </div>
                  <div className="clr30"></div>
                </div>
              </div>
              <iframe src='https://webapi.entab.info/api/image/SMSMU/public/pdf/TC-sample.pdf#toolbar=0' width="100%" height="500" title="Transfer Certificate Sample"></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TC;
