import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"
const comingSoon = () => {
    return(
        <>
        <Header />
      <Breadcrumb mid="Coming Soon" breadcrumbName="Coming Soon" />

      <div className="innerSection">
        <div className="container">         
          <div className="row">
            <div className="col-md-12">
            <div className="title">
                <div>
                    <h1><span>Coming  </span> Soon</h1>
                </div>
            </div>
               
            </div>
            <div className="col-md-12">
               
                <img src="https://webapi.entab.info/api/image/SMSMU/public/Images/comingSoon.jpg" className="img-fluid m-auto d-block" alt="St mary school mirzapur"  />
            </div>
          </div>
        </div>
      </div>
      <Footer /> 
      </>
    )
}
export default comingSoon