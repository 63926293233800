import React from "react"; 
import Header from "../components/Header";
import Footer from "../components/Footer"; 
import Breadcrumb from "../components/Breadcrumb";
const ManagementCommittee = () => {

  return (
    <>
      <Header />
      <Breadcrumb mid="Academics" breadcrumbName="Management Committee" />

      <div className="innerSection">
        <div className="container">         
          <div className="row">
            <div className="col-md-12">
            <div className="title">
                <div>
                    <h1>Management Committee</h1>
                </div>
            </div>
               
            </div>
            <div className="col-md-4 mt-4">
              <div className="management_commit">
                <img src="https://webapi.entab.info/api/image/SMSMU/public/Images/principal-jacob-bona-02.jpg" alt="St mary school, mirzapur" />
                <h3>Rev. Fr. Jacob Bona D’ Souza</h3>
                <p>PRINCIPAL & MANAGER</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="management_commit">
                <img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Louis-Mascarenhas-1.jpg" alt="St mary school, mirzapur" />
                <h3>Most. Rev. Dr. Louis Mascarenhas</h3>
                <p>(BISHOP OF ALLAHABAD)</p>
                <p>CHAIRMAN OF ST. MARY'S EDU. SOCIETY</p>
              </div>
            </div>
            
            <div className="col-md-4 mt-4">
              <div className="management_commit">
                <img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Fr-Lawrence-Fernandes.png" alt="St mary school, mirzapur" />
                <h3>Rev. Fr.Lawrence Fernandes</h3>
                <p>-</p>
              </div>
            </div>
            
          
          </div>
        </div>
      </div>
      <Footer /> 
    </>
  )
}

export default ManagementCommittee