import React, { useState, useEffect } from "react"; 
import Header from "../components/Header";
import Footer from "../components/Footer"; 
import Breadcrumb from "../components/Breadcrumb";
import { getFaculty } from '../Service/Api';

const Faculty = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const getResponse = await getFaculty();
            setData(getResponse);
        };
        fetchData();
    }, []); // Adding an empty array as dependency

    return (
        <>
            <Header />
            <Breadcrumb mid="Academics" breadcrumbName="Faculty & Staff" />

            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1>Faculty & Staff</h1>
                                </div>
                            </div>
                        </div>

                        {/* Check if data has length, then map through it */}
                        {data.length > 0 ? (
                            data.map((item, index) => (
                                <div className="col-md-3" key={index}>
                                    <div className="faculty">
                                        {item.attachments.length>0 ? ( <img src={`https://webapi.entab.info/api/image/${item?.attachments[0]}`} alt="St mary school mirzapur" />):(<img src="https://webapi.entab.info/api/image/SMSMU/public/Images/dummy.jpg" alt="St mary school mirzapur" className="img-fluid" />)}
                                        
                                        <h3>{item.name}</h3>
                                        <p>{item.designation}</p>
                                        <p>{item.qualification}</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            // Fallback in case no data is present
                            <div className="col-md-3">
                                <div className="faculty">
                                    <img src="https://webapi.entab.info/api/image/SMSMU/public/Images/dummy.jpg" alt="St mary school mirzapur" className="img-fluid" />
                                    <h3>Faculty Name</h3>
                                    <p>Designation</p>
                                    <p>Qualification</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Faculty;
